
@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-calendars/styles/material.css";
@import "../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-schedule/styles/material.css";

.u-header-topbar {
  border-bottom: 1px solid #fea201;
  background: #fea201;
}
.bg-yellow  {
  background-color: #fea201 !important;
  color: #fff;
}

footer{
  background-color: #fea201 !important;

}
.btn-primary {
  background-color: #4285f4 !important;
  border-color: #fed700;
}
.btn{
  border-radius: 5px;
}
nav.js-mega-menu.navbar.navbar-expand-md.u-header__navbar.u-header__navbar--no-space {
  box-shadow: none;
  left: 0%;
    right: 0px;
    position: relative;
}
a.nav-link.u-header__nav-link {
  color: black;
}