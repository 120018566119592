.quick-info-template .quick-info-header {
    background-color: white;
    padding: 8px 18px;
  }
  
  .quick-info-template .quick-info-header-content {
    justify-content: flex-end;
    display: flex;
    flex-direction: column;
    padding: 5px 10px 5px;
  }
  
  .quick-info-template .quick-info-title {
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.48px;
    height: 22px;
  }
  
  .quick-info-template .duration-text {
    font-size: 11px;
    letter-spacing: 0.33px;
    height: 14px;
  }
  
  .quick-info-template .content-area {
    margin: 0;
    padding: 10px;
    width: auto;
  }
  
  .quick-info-template .event-content {
    height: 90px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 15px;
  }
  
  .quick-info-template .meeting-type-wrap,
  .quick-info-template .meeting-subject-wrap,
  .quick-info-template .notes-wrap {
    font-size: 11px;
    color: #666;
    letter-spacing: 0.33px;
    height: 24px;
    padding: 5px;
  }
  
  .quick-info-template .event-content div label {
    display: inline-block;
    min-width: 45px;
    color: #666;
  }
  
  .quick-info-template .event-content div span {
    font-size: 11px;
    color: #151515;
    letter-spacing: 0.33px;
    line-height: 14px;
    padding-left: 8px;
  }
  
  .quick-info-template .cell-footer.e-btn {
    background-color: #ffffff;
    border-color: #878787;
    color: #878787;
  }
  
  .quick-info-template .cell-footer {
    padding-top: 10px;
  }
  
  .quick-info-template .e-quick-popup-wrapper .e-cell-popup .e-popup-content {
    padding: 0 14px;
  }
  
  .quick-info-template .e-quick-popup-wrapper .e-event-popup .e-popup-footer {
    display: block;
  }
  
  .quick-info-template .e-quick-popup-wrapper .e-popup-footer button:first-child {
    margin-right: 5px;
  }

  .e-schedule .e-vertical-view .e-date-header-wrap table col, .e-schedule .e-vertical-view .e-content-wrap table col { 
    width:  100px; 
} 
.template-wrap {
    position: relative;
    left: 30%;
}

.bg-sky{
  background-color: rgb(165, 223, 248);
  }

  .e-work-cells {
    background-color: #C1C5CA !important;
  }
  .e-work-hours {
    background-color: white !important;
  }

  .custom{
    background-color: #151515;
  }

  #hoverText {
      background-color: rgba(0,0,0,.2);
      margin: 5px;
      color: #fff;
  }