.permission-table{
    width: 100%;
}
.permission-table td{
    padding: 0px 1px 4px 21px;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice, .select2-container--default .select2-results__option[aria-selected="true"], .select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: #6777ef;
    color: black;
}

#container {
    position: relative;
    width: 968px;
    margin: 0 auto;
    transition: ease-in
  }
  
  #container ul {
    list-style: none;
  }
  
  #container .buttons {
    margin: 10px 0px 0 380px;
  }
  
  #container .grid li:hover {
    box-shadow: 4px 4px 9px rgba(0, 0, 0, 0.4);
    transition: 0.5s ease;
  }
  
  #container .grid li {
    float: left;
    width: 31%;
    box-shadow: 2px 2px 3px rgb(0 0 0 / 40%);
    border-top: 8px solid #860251;
    margin: 10px 10px;
    padding: 0 20px 20px 20px;
    text-align: left;
  }
  
  #container .list li {
    float: left;
    width: 100%;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.4);
    margin: 2px 15px;
    padding: 0 20px 15px;
    text-align: left;
  }
  
  #container .list li:hover {
    box-shadow: 4px 0px 9px rgba(0, 0, 0, 0.4);
    transition: 0.5s ease;
  }
  
  #container .list li button {
    display: none;
  }
  
  #container li:nth-child(odd) {
    background: #ffffff;
  }
  
  #container li:first-child {
    border-top: 8px solid #860251;
  }
  
  #container li:nth-child(even) {
    background: #ede5e9;
  }
  
  .float-left {
    float: left;
    padding-right: 20px;
  }
  
  .green-btn {
    background: green;
    border: none;
    border-radius: 3px;
    padding: 15px 20px;
    font-family: 'Helvetica', sans-serif;
    font-size: 16px;
    color: #ffffff;
    margin-top: 15px;
  }
  
  .green-btn:hover {
    background: #e8e8e8;
    color: green;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
  }
  
  .square-btn {
    width: 60px;
    height: 60px;
    margin: 0 5px;
    border: 2px solid #d6d6d6;
    background: #ffffff;
    border-radius: 3px;
  }
  
  .square-btn:hover {
    background: #58595b;
    color: #ffffff;
  }
  
  .square-btn:active {
    background: #58595b;
    color: #ffffff;
  }
  
  a {
    border: none;
    color: green;
  }
  
  .author {
    font-weight: bold;
    font-family: 'Helvetica', sans-serif;
    font-size: 12px;
    padding-right: 10px;
    border-right: 1px solid #626262;
  }
  
  .date {
    font-weight: normal;
    font-family: 'Helvetica', sans-serif;
    font-size: 12px;
    padding: 0 10px;
    border-right: 1px solid #626262;
  }
  
  .time {
    font-weight: normal;
    font-family: 'Helvetica', sans-serif;
    font-size: 12px;
    padding: 0 10px;
  }
  
  h2 {
    font-family: 'Helvetica', sans-serif;
    font-weight:bold;
    font-size: 27px;
    line-height: 20px;
  }
  
  button {
    outline: none;
  }
  button .square-btn:active {
    background: #58595b;
    color: #ffffff;
  }

  .list-content{
    float: right;
    position: absolute;
    /* top: 166%; */
    left: 20%;
    margin-top: -9%;
  }