#dropdownButton {
    border: none;
    box-shadow: none;
    background: none;
    font-size: 20px;
}

#dropdownButton:hover {
    background: none !important;
    color: black !important;
}