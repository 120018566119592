/* .schedule-tables {
    overflow-y: auto;
} */
.schedule-employees {
    float: left;
    width: 20%;
}
.employee-table {
    border-left: 1px solid #d9d9d9;
}
.schedule-table, .employee-table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.schedule-employee-hours {
    float: left;
    width: 80%;
}
.schedule-table, .employee-table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.schedule-table tbody, .employee-table tbody {
    background-color: #fff;
}
.schedule-table tr.closed-dates th, .employee-table tr.closed-dates th {
    border-radius: 5px 5px 0 0;
    border-width: 0 1px;
    border-color: #fff;
    padding: 0 2px;
}
.schedule-table th, .employee-table th {
    padding: 10px;
}
.schedule-table th, .schedule-table td, .employee-table th, .employee-table td {
    text-align: center;
    vertical-align: middle;
    border: 1px solid #d9d9d9;
    cursor: default;
    min-width: 152px;
}
.schedule-table td.day, .schedule-table td.range, .employee-table td.day, .employee-table td.range {
    vertical-align: top;
}
.schedule-table td, .employee-table td {
    height: 40px;
    -webkit-transition: background-color 0.2s;
    transition: background-color 0.2s;
}
.schedule-table th, .schedule-table td, .employee-table th, .employee-table td {
    text-align: center;
    vertical-align: middle;
    border: 1px solid #d9d9d9;
    cursor: default;
    min-width: 152px;}
    .schedule-table td.day .schedule-hours:hover, .schedule-table td.range .schedule-hours:hover, .employee-table td.day .schedule-hours:hover, .employee-table td.range .schedule-hours:hover {
        background-color: #c3e5f5;
    }
    .schedule-table td.day .schedule-hours, .schedule-table td.range .schedule-hours, .employee-table td.day .schedule-hours, .employee-table td.range .schedule-hours {
        background-color: #b4dff2;
    }
    .schedule-table .clickable, .employee-table .clickable {
        cursor: pointer;
    }
    .schedule-hours {
        border-radius: 2px;
        padding: 6px 10px;
        color: #24334A;
        margin: 3px;
        text-align: left;
    }
    .schedule-table, .employee-table {
        width: 100%;
        border-collapse: collapse;
        table-layout: fixed;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    .schedule-table td, .employee-table td {
        height: 40px;
        -webkit-transition: background-color 0.2s;
        transition: background-color 0.2s;
    }
    .schedule-loading-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding-top: 100px;
        text-align: center;
        background-color: rgba(255,255,255,0.8);
    }
    .schedule-table td.empty:not(.closed):after, .employee-table td.empty:not(.closed):after {
        content: '+';
        display: block;
        font-size: 2em;
        color: #626262;
        opacity: 0;
        -webkit-transition: opacity 0.3s ease-in-out;
        transition: opacity 0.3s ease-in-out;
    }

    /* @media (min-width: 768px)
{ */
    .schedule-table td.empty:hover:after, .employee-table td.empty:hover:after {
    opacity: 1;
}
/* } */
/* @media (min-width: 768px)
{ */
.schedule-table td.empty:not(.closed):after, .employee-table td.empty:not(.closed):after {
    content: '+';
    display: block;
    font-size: 2em;
    color: #626262;
    opacity: 0;
    -webkit-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out;
}
.ReactModal__Overlay.ReactModal__Overlay--after-open{
    z-index: 9999;
}
.ReactModal__Content.ReactModal__Content--after-open {
    position: absolute;
    inset: 40px;
    border: transparent !important;
    background: transparent !important;
    overflow: hidden !important;
    border-radius: 4px;
    outline: none;
    padding: 20px;
}
.schedule-table td, .employee-table td {
    height: 47px;
    transition: background-color 0.2s;
}
.ant-picker-dropdown {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum', "tnum";
    position: absolute;
    z-index: 99999 !important;
}
.cross{
    position: absolute;
    top: 45%;
    right: 3%;
    background: aliceblue;
    padding: 8px;
    border-radius: 70px;
}
/* }
.modal-body {
    padding: 3.875rem;
}
.ReactModal__Overlay.ReactModal__Overlay--after-open
{
    background-color: rgb(197 197 197 / 75%) !important;
} */
.page-item {
    margin-left: 0px;
    margin-right: -7px;
}
.react-datepicker-popper {
    left: -158% !important;
}
#container li {
    background: #ffffff !important;
    border-radius: 7px;
    border-top: none !important;
    box-shadow: 0 4px 8px 0 rgb(16 25 40 / 10%);
}
#container .grid li {
    float: left;
    width: 31%;
    box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 40%);
    border-top: 8px solid #860251;
    margin: 10px 10px;
    padding: 0 20px 20px 20px;
    text-align: left;
    height: 45vh;
}